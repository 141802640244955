import React, { useEffect, useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Divider,
  Badge,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  SpaceDashboard,
  EventNote,
  Home,
  Key,
  ExitToApp,
  ChevronLeft,
  ChevronRight,
  Notifications,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import userService from '../services/userService';
import { useAuth0 } from '@auth0/auth0-react';

const drawerWidth = 240;
const collapsedWidth = 60;

const Sidebar = ({ open, toggleSidebar, collapsed, setCollapsed, onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isInitialized, setIsInitialized] = useState(false);
  const [title, setTitle] = useState('');
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout, getAccessTokenSilently } = useAuth0();

  const routeTitles = {
    '/dashboard': 'Dashboard',
    '/bookings': 'Bookings',
    '/rentals': 'Rentals',
    '/apikeys': 'API Keys',
  };

  useEffect(() => {
    const currentTitle = routeTitles[location.pathname] || '';
    setTitle(currentTitle);
  }, [location.pathname]);

  useEffect(() => {
    const persistedCollapsed = localStorage.getItem('sidebarCollapsed');
    if (persistedCollapsed !== null) {
      setCollapsed(JSON.parse(persistedCollapsed));
    }
    setIsInitialized(true);
  }, [setCollapsed]);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await userService.getUnreadNotifications(token);
        setNotifications(response.data || []); // Adjusted to match response structure
        setUnreadCount(response.data?.length || 0);
      } catch (error) {
        console.error(error);
      }
    };

    fetchNotifications();
  }, [getAccessTokenSilently]);

  const handleToggleCollapse = () => {
    const newCollapsedState = !collapsed;
    setCollapsed(newCollapsedState);
    localStorage.setItem('sidebarCollapsed', JSON.stringify(newCollapsedState));
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (isMobile) {
      toggleSidebar();
    }
  };

  const handleNotificationsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAsRead = async (notificationID) => {
    try {
      const token = await getAccessTokenSilently();
      await userService.markNotificationAsRead(token, notificationID);
      setNotifications((prev) =>
        prev.filter((notification) => notification.id !== notificationID)
      );
      setUnreadCount((prev) => prev - 1);
    } catch (error) {
      console.error(error);
    }
  };

  const renderNotificationMessage = (message) => {
    return message.replace(/U\+([0-9A-Fa-f]{4,6})/g, (_, hex) => {
      return String.fromCodePoint(parseInt(hex, 16));
    });
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Convert to local time format
  };

  if (!isInitialized) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {/* AppBar */}
      <AppBar
        position="fixed"
        color="default"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          transition: 'margin 0.3s',
          marginLeft: collapsed && !isMobile ? `${collapsedWidth}px` : `${drawerWidth}px`,
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={isMobile ? toggleSidebar : handleToggleCollapse}
            sx={{ marginRight: 2 }}
          >
            {isMobile ? <MenuIcon /> : collapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <IconButton
            onClick={handleNotificationsClick}
            aria-controls="notification-menu"
            aria-haspopup="true"
          >
            <Badge badgeContent={unreadCount} color="error">
              <Notifications />
            </Badge>
          </IconButton>
          <Menu
            id="notification-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            sx={{
              width: isMobile ? '100%' : 'auto', // Full width on mobile
              maxWidth: '90vw', // Limit width on mobile
            }}
          >
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <MenuItem
                  key={notification.id}
                  onClick={() => handleMarkAsRead(notification.id)}
                  sx={{
                    whiteSpace: 'normal', // Allow wrapping
                    wordWrap: 'break-word', // Break long words
                    overflow: 'hidden', // Ensure no overflow
                  }}
                >
                  <div>
                    <div>{renderNotificationMessage(notification.message)}</div>
                    <Typography variant="caption" color="textSecondary">
                      {formatTimestamp(notification.created_at)}
                    </Typography>
                  </div>
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No new notifications</MenuItem>
            )}
          </Menu>
        </Toolbar>
      </AppBar>

      {/* Sidebar */}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={open}
        onClose={toggleSidebar}
        sx={{
          width: collapsed && !isMobile ? collapsedWidth : drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: collapsed && !isMobile ? collapsedWidth : drawerWidth,
            boxSizing: 'border-box',
            transition: 'width 0.3s',
          },
        }}
      >
        <Toolbar />
        <Divider />
        <List>
          {[
            { path: '/dashboard', label: 'Dashboard', icon: <SpaceDashboard /> },
            { path: '/bookings', label: 'Bookings', icon: <EventNote /> },
            { path: '/rentals', label: 'Rentals', icon: <Home /> },
            { path: '/apikeys', label: 'API Keys', icon: <Key /> },
          ].map(({ path, label, icon }) => (
            <ListItem button key={path} onClick={() => handleNavigation(path)}>
              <ListItemIcon>{icon}</ListItemIcon>
              {(!collapsed || isMobile) && <ListItemText primary={label} />}
            </ListItem>
          ))}
        </List>
        <Divider />
        <List
          sx={{
            marginTop: isMobile ? 0 : 'auto',
            paddingBottom: isMobile ? '16px' : 0,
            position: isMobile ? 'absolute' : 'static',
            bottom: isMobile ? 0 : 'unset',
            width: '100%',
          }}
        >
          <ListItem button onClick={onLogout}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            {(!collapsed || isMobile) && <ListItemText primary="Logout" />}
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;

import { IosShare } from '@mui/icons-material';
import axios from 'axios';

const API_URL = process.env.REACT_APP_BACKEND_URL;


const getUnreadNotifications = (token) => {
  console.debug("Getting Unread Notifications")
  return axios.get(`${API_URL}/user/notifications`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const markNotificationAsRead = (token, notificationID) => {
  console.debug("Marking notification as read")
  return axios.post(`${API_URL}/user/notifications/${notificationID}/read`, "", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export default {
  getUnreadNotifications,
  markNotificationAsRead,
};
